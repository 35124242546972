import firebase from 'firebase/compat/app'
import { NoAuthUserError } from '#services/auth/errors'
import { RecaptchaFailedError } from '#services/clientErrors'
import { mapAuthProviderError } from './mapAuthProviderError'

export type AuthUser = firebase.User
export type AuthProviderError = firebase.auth.AuthError | firebase.auth.MultiFactorError
export type MultiFactorResolver = firebase.auth.MultiFactorResolver
export type MultiFactorResolverWithPhoneMFAHint = Omit<
  firebase.auth.MultiFactorResolver,
  'hints'
> & {
  hints: firebase.auth.PhoneMultiFactorInfo[]
}
export type RecaptchaVerifier = firebase.auth.RecaptchaVerifier

export type AuthActionPromise = Promise<void>

export const getAuthProvider = () => firebase.auth()

const getAuthProviderUser = (): AuthUser => {
  const user = getAuthProvider().currentUser
  if (!user) {
    throw new NoAuthUserError()
  }
  return user
}

export const login = (username: string, password: string): AuthActionPromise =>
  // Avoiding async/await here to prevent errors
  // https://github.com/firebase/firebase-js-sdk/issues/1881#issuecomment-501886866
  new Promise((resolve, reject) =>
    getAuthProvider()
      .setPersistence('local')
      .then(() => {
        getAuthProvider()
          .signInWithEmailAndPassword(username, password)
          .then(() => resolve())
          .catch((err) => reject(mapAuthProviderError(err as AuthProviderError)))
      }),
  )

export type SSOProviderId = 'saml.onelogin'

export const loginWithSSO = (providerId: SSOProviderId) =>
  new Promise((_, reject) => {
    const samlAuthProvider = new firebase.auth.SAMLAuthProvider(providerId)
    getAuthProvider()
      .signInWithPopup(samlAuthProvider)
      .catch((err) => reject(mapAuthProviderError(err as AuthProviderError)))
  })

export const logout = (): Promise<void> => getAuthProvider().signOut()

export const getToken = async (forceRefresh = false) => {
  try {
    const user = getAuthProviderUser()
    return await user.getIdToken(forceRefresh)
  } catch (e) {
    return null
  }
}

export const confirmPasswordReset = async (code: string, newPassword: string): Promise<void> =>
  new Promise((resolve, reject) =>
    getAuthProvider()
      .confirmPasswordReset(code, newPassword)
      .then(() => resolve())
      .catch((err) => reject(mapAuthProviderError(err as AuthProviderError))),
  )

export const unenrollMultifactor = () => {
  const user = getAuthProviderUser()
  const { enrolledFactors } = user.multiFactor
  return user.multiFactor.unenroll(enrolledFactors[0] || '').then(() => {
    // eslint-disable-next-line no-console
    console.log('Successfully unenrolled')
    window.location.pathname = 'login'
  })
}

export const triggerLoginMFA = (
  resolver: MultiFactorResolver,
  recaptchaVerifier?: RecaptchaVerifier,
) => {
  if (!recaptchaVerifier) {
    throw new RecaptchaFailedError()
  }

  // TODO: ask user which second factor to use
  const selectedFactorIndex = 0
  if (
    resolver.hints[selectedFactorIndex]?.factorId ===
    firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
  ) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedFactorIndex],
      session: resolver.session,
    }
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
  }

  throw new Error('unsupported 2fa method')
}

export const confirmLoginMFA = (
  code: string,
  verificationId: string,
  resolver: MultiFactorResolver,
) => {
  const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, code)
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
  return resolver.resolveSignIn(multiFactorAssertion)
}

export const triggerMFAEnrollment = async (
  phoneNumber: string,
  recaptchaVerifier?: RecaptchaVerifier,
): Promise<string> => {
  if (!recaptchaVerifier) {
    throw new RecaptchaFailedError()
  }
  return new Promise((resolve, reject) => {
    getAuthProviderUser()
      .multiFactor.getSession()
      .then((session) => {
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
        phoneAuthProvider
          .verifyPhoneNumber({ phoneNumber, session }, recaptchaVerifier)
          .then(resolve)
          .catch((err) => reject(mapAuthProviderError(err as AuthProviderError)))
        // TODO: Handle verifyPhoneNumber errors - https://app.asana.com/0/1199950755555247/1200341384357292
      })
      .catch((err) => reject(mapAuthProviderError(err as AuthProviderError)))
  })
}

export const confirmMFAEnrollment = (code: string, verificationId: string): Promise<void> =>
  // Avoiding async/await here to prevent errors
  // https://github.com/firebase/firebase-js-sdk/issues/1881#issuecomment-501886866
  new Promise((resolve, reject) => {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
    getAuthProviderUser()
      .multiFactor.enroll(multiFactorAssertion, 'net-purpose')
      .then(() => resolve())
      .catch((err) => reject(mapAuthProviderError(err as AuthProviderError)))
  })
