import { Portfolio } from '@netpurpose/api'
import { CreatePortfolio, ResultStatus } from '@netpurpose/types'
import { DiscriminateUnion } from '#services/typeHelpers'

export enum TaskType {
  PortfolioCreation = 'portfolioCreation',
  PortfolioExport = 'portfolioExport',
  PortfolioPAIsExport = 'portfolioPAIsExport',
  PortfolioSDGsExport = 'portfolioSDGsExport',
  PortfolioSDGRevenueExport = 'portfolioSDGRevenueExport',
  EntityExport = 'entityExport',
  DataDictionaryExport = 'dataDictionaryExport',
}

export interface ITask {
  type: TaskType
  initial: unknown
  result: string
}

export interface PortfolioCreation extends ITask {
  type: TaskType.PortfolioCreation
  initial: CreatePortfolio
}

export interface PortfolioExport extends ITask {
  type: TaskType.PortfolioExport
  initial: Portfolio['id']
}

export interface PortfolioPAIsExport extends ITask {
  type: TaskType.PortfolioPAIsExport
  initial: Portfolio['id']
}

export interface PortfolioSDGsExport extends ITask {
  type: TaskType.PortfolioSDGsExport
  initial: Portfolio['id']
}

export interface PortfolioSDGRevenueExport extends ITask {
  type: TaskType.PortfolioSDGRevenueExport
  initial: Portfolio['id']
}

export interface EntityExport extends ITask {
  type: TaskType.EntityExport
  initial: number
}

export interface DataDictionaryExport extends ITask {
  type: TaskType.DataDictionaryExport
  initial: 'data-dictionary-export'
}

export type Task =
  | PortfolioCreation
  | PortfolioExport
  | PortfolioPAIsExport
  | PortfolioSDGsExport
  | PortfolioSDGRevenueExport
  | EntityExport
  | DataDictionaryExport

export type TaskTypeAndStatus = {
  type: TaskType
  status: ResultStatus
}

export type Action<T extends Task> =
  | {
      actionType: 'new'
      taskType: T['type']
      token: string
      initial: T['initial']
      portfolioName?: string
      metricConfigId?: string
      entityName?: string
    }
  | {
      actionType: 'statusUpdate'
      taskType: T['type']
      token: string
      newStatus: ResultStatus
      result?: T['result']
    }
  | {
      actionType: 'remove'
      taskType: T['type']
      result: T['result'] | undefined
    }

type DistributeActions<U extends Task> = U extends unknown ? Action<U> : never
export type Actions = DistributeActions<Task>

export type TaskStatus<T extends Task> = {
  status: ResultStatus
  initial: T['initial']
  result?: T['result']
  portfolioName?: string
  metricConfigId?: string
  entityName?: string
}

type TaskStatusById<T extends Task> = Record<string, TaskStatus<T>>

type MapIntoState<T extends Task> = {
  [V in T['type']]: TaskStatusById<DiscriminateUnion<T, 'type', V>>
}
export type State = MapIntoState<Task>

export type PortfolioCreationTask = TaskStatus<PortfolioCreation>

export type PortfolioExportRequest = TaskStatus<PortfolioExport>

export type PortfolioPAIsExportRequest = TaskStatus<PortfolioPAIsExport>

export type PortfolioSDGsExportRequest = TaskStatus<PortfolioSDGsExport>

export type PortfolioSDGRevenueExportRequest = TaskStatus<PortfolioSDGRevenueExport>

export type EntityExportRequest = TaskStatus<EntityExport>

export type DataDictionaryExportRequest = TaskStatus<DataDictionaryExport>

export type ITaskContext = {
  createPortfolio: (token: string, portfolio: CreatePortfolio) => void
  requestPortfolioExport: (
    token: string,
    portfolioId: number,
    portfolioName: string,
    metricConfigId: string,
  ) => void
  requestPortfolioPAIsExport: (token: string, portfolioId: number, portfolioName: string) => void
  requestPortfolioSDGsExport: (token: string, portfolioId: number, portfolioName: string) => void
  requestPortfolioSDGRevenueExport: (
    token: string,
    portfolioId: number,
    portfolioName: string,
  ) => void
  requestEntityExport: (token: string, entityId: number, entityName: string) => void
  requestDataDictionaryExport: (token: string) => void
  removeExport: (taskType: TaskType, result: string | undefined) => void
  portfolioCreationTasks: PortfolioCreationTask[]
  portfolioExportRequests: PortfolioExportRequest[]
  portfolioPAIsExportRequests: PortfolioPAIsExportRequest[]
  portfolioSDGsExportRequests: PortfolioSDGsExportRequest[]
  portfolioSDGRevenueExportRequests: PortfolioSDGRevenueExportRequest[]
  entityExportRequests: EntityExportRequest[]
  dataDictionaryExportRequests: DataDictionaryExportRequest[]
}
